var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-table-simple",
    {
      staticStyle: { "max-height": "65vh", "font-size": "12px" },
      attrs: { bordered: "", responsive: "", "sticky-header": "" },
    },
    [
      _c("colgroup", [
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "250px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "80px" } }),
        _c("col", { staticStyle: { width: "135px" } }),
        _c("col", { staticStyle: { width: "150px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
        _c("col", { staticStyle: { width: "120px" } }),
      ]),
      _c(
        "b-thead",
        { attrs: { "head-variant": "light" } },
        [
          _c(
            "b-tr",
            [
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("ID")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("제약사")]
              ),
              _c("b-th", { attrs: { rowspan: "2" } }, [_vm._v("제품명")]),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("지역")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("회차")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("기간")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("일자")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("진료과")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: {
                    "text-align": "center",
                    "padding-top": "0.8rem",
                    "padding-bottom": "0.8rem",
                  },
                  attrs: { colspan: "2" },
                },
                [_vm._v("구간 조건")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { colspan: "3" },
                },
                [_vm._v("1회 구간 노출수")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { colspan: "3" },
                },
                [_vm._v("2회 구간 노출수")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("메세지"), _c("br"), _vm._v("클릭수")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("클릭수"), _c("br"), _vm._v("(중복제거)")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [_vm._v("닥터인포"), _c("br"), _vm._v("클릭수")]
              ),
              _c(
                "b-th",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { rowspan: "2" },
                },
                [
                  _vm._v("닥터인포"),
                  _c("br"),
                  _vm._v("클릭수"),
                  _c("br"),
                  _vm._v("(중복제거)"),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-tr",
            [
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [_vm._v("1회 구간")]
              ),
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [_vm._v("2회 구간")]
              ),
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [
                  _vm._v(
                    _vm._s(_vm.searchType === 2 ? "처방의원수" : "처방의사수")
                  ),
                ]
              ),
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [
                  _vm._v(
                    _vm._s(_vm.searchType === 2 ? "신규처방처" : "신규처방수")
                  ),
                ]
              ),
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [_vm._v(_vm._s(_vm.searchType === 2 ? "재처방처" : "재처방수"))]
              ),
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [
                  _vm._v(
                    _vm._s(_vm.searchType === 2 ? "처방의원수" : "처방의사수")
                  ),
                ]
              ),
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [
                  _vm._v(
                    _vm._s(_vm.searchType === 2 ? "신규처방처" : "신규처방수")
                  ),
                ]
              ),
              _c(
                "b-th",
                { staticStyle: { "text-align": "center", top: "40px" } },
                [_vm._v(_vm._s(_vm.searchType === 2 ? "재처방처" : "재처방수"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-tbody",
        [
          _vm._l(_vm.data, function (daily) {
            return [
              _vm._l(daily.liveDiPeriods, function (period, pIdx) {
                return [
                  _vm._l(period.liveDiDaily, function (day, dIdx) {
                    return [
                      _vm._l(day.liveDiDepts, function (dept, index) {
                        return [
                          _c(
                            "b-tr",
                            {
                              key:
                                "daily-" +
                                daily.liveInfoId +
                                "-" +
                                daily.regionName +
                                "-" +
                                period.seq +
                                "-" +
                                pIdx +
                                "-" +
                                dIdx +
                                "-" +
                                index,
                              style:
                                daily.regionName === "(미분류제외) 전체 합"
                                  ? "background-color: rgba(127, 127, 255, 0.1);"
                                  : "",
                            },
                            [
                              pIdx === 0 && dIdx === 0 && index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan:
                                          daily.rowSpanCount +
                                          daily.liveDiPeriods.length +
                                          daily.dayCount,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(/*ID*/ daily.liveInfoId) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              pIdx === 0 && dIdx === 0 && index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan:
                                          daily.rowSpanCount +
                                          daily.liveDiPeriods.length +
                                          daily.dayCount,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(/*제약사*/ daily.clientName) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              pIdx === 0 && dIdx === 0 && index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      attrs: {
                                        rowspan:
                                          daily.rowSpanCount +
                                          daily.liveDiPeriods.length +
                                          daily.dayCount,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(/*제품명*/ daily.brandName) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              pIdx === 0 && dIdx === 0 && index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan:
                                          daily.rowSpanCount +
                                          daily.liveDiPeriods.length +
                                          daily.dayCount,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(/*지역*/ daily.regionName) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              dIdx === 0 && index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan:
                                          period.rowSpanCount +
                                          period.dayCount +
                                          1,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(/*회차*/ period.seq) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              dIdx === 0 && index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan:
                                          period.rowSpanCount +
                                          period.dayCount +
                                          1,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            /*기간*/ period.periodStr.replaceAll(
                                              "~",
                                              " ~ "
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan: day.liveDiDepts.length + 1,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(/*일자*/ day.dateStr) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(/*진료과*/ dept.medicalDeptName)
                                  ),
                                ]
                              ),
                              index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan: day.liveDiDepts.length,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            /*구간조건(1회)*/ period.firstCount
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              index === 0
                                ? _c(
                                    "b-td",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: {
                                        rowspan: day.liveDiDepts.length,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("$numberFormatter")(
                                            /*구간조건(2회)*/ period.secondCount
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("$numberFormatter")(
                                        /*1회 노출수 처방처*/ dept.firstTermGroupCount
                                      )
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("$numberFormatter")(
                                        /*1회 노출수 신규처방*/ dept.firstTermNewCount
                                      )
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("$numberFormatter")(
                                        /*1회 노출수 재처방*/ dept.firstTermReCount
                                      )
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("$numberFormatter")(
                                        /*2회 노출수 처방처*/ dept.secondTermGroupCount
                                      )
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("$numberFormatter")(
                                        /*2회 노출수 신규처방*/ dept.secondTermNewCount
                                      )
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("$numberFormatter")(
                                        /*2회 노출수 재처방*/ dept.secondTermReCount
                                      )
                                    ) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          /*메시지 클릭수*/ dept.totalClickCount
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          /*클릭수_중복제거*/ dept.groupClickCount
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          /*닥터인포 클릭수*/ dept.totalDrInfoClickCount
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("$numberFormatter")(
                                          /*닥터인포 클릭수_중복제거*/ dept.groupDrInfoClickCount
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      }),
                      _c(
                        "b-tr",
                        {
                          key:
                            "daily-dailytotal-" +
                            daily.liveInfoId +
                            "-" +
                            daily.regionName +
                            "-" +
                            period.seq +
                            "-" +
                            day.dateStr,
                          staticClass: "h6 font-weight-bolder",
                          staticStyle: {
                            "background-color": "rgba(127, 127, 127, 0.2)",
                          },
                        },
                        [
                          _vm.useDailyTotal
                            ? _c(
                                "b-td",
                                {
                                  staticStyle: { "text-align": "center" },
                                  attrs: { colspan: 3 },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        /*일자 + 진료과 + 구간조건(1회) + 구간조건(2회)*/ "일자별 소계"
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    /*1회 노출수 처방처*/ day.dailyTotal
                                      .firstTermGroupCount
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    /*1회 노출수 신규처방*/ day.dailyTotal
                                      .firstTermNewCount
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    /*1회 노출수 재처방*/ day.dailyTotal
                                      .firstTermReCount
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    /*2회 노출수 처방처*/ day.dailyTotal
                                      .secondTermGroupCount
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    /*2회 노출수 신규처방*/ day.dailyTotal
                                      .secondTermNewCount
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("$numberFormatter")(
                                    /*2회 노출수 재처방*/ day.dailyTotal
                                      .secondTermReCount
                                  )
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      /*메시지 클릭수*/ day.dailyTotal
                                        .totalClickCount
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      /*클릭수_중복제거*/ day.dailyTotal
                                        .groupClickCount
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      /*닥터인포 클릭수*/ day.dailyTotal
                                        .totalDrInfoClickCount
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "b-td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("$numberFormatter")(
                                      /*닥터인포 클릭수_중복제거*/ day
                                        .dailyTotal.groupDrInfoClickCount
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  }),
                  _c(
                    "b-tr",
                    {
                      key:
                        "daily-subtotal-" +
                        daily.liveInfoId +
                        "-" +
                        daily.regionName +
                        "-" +
                        period.seq,
                      staticClass: "h6 font-weight-bolder",
                      staticStyle: {
                        "background-color": "rgba(127, 255, 127, 0.2)",
                      },
                    },
                    [
                      _vm.useSubTotal
                        ? _c(
                            "b-td",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { colspan: 4 },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    /*일자 + 진료과 + 구간조건(1회) + 구간조건(2회)*/ "회차별 소계"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              /*1회 노출수 처방처*/ period.subTotal
                                .firstTermGroupCount
                            )
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              /*1회 노출수 신규처방*/ period.subTotal
                                .firstTermNewCount
                            )
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              /*1회 노출수 재처방*/ period.subTotal
                                .firstTermReCount
                            )
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              /*2회 노출수 처방처*/ period.subTotal
                                .secondTermGroupCount
                            )
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              /*2회 노출수 신규처방*/ period.subTotal
                                .secondTermNewCount
                            )
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              /*2회 노출수 재처방*/ period.subTotal
                                .secondTermReCount
                            )
                          ) + " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("$numberFormatter")(
                                /*메시지 클릭수*/ period.subTotal
                                  .totalClickCount
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("$numberFormatter")(
                                /*클릭수_중복제거*/ period.subTotal
                                  .groupClickCount
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("$numberFormatter")(
                                /*닥터인포 클릭수*/ period.subTotal
                                  .totalDrInfoClickCount
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("b-td", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("$numberFormatter")(
                                /*닥터인포 클릭수_중복제거*/ period.subTotal
                                  .groupDrInfoClickCount
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }