<template>
  <b-table-simple
    bordered
    responsive
    sticky-header
    style="max-height: 65vh; font-size: 12px;"
  >
    <colgroup>
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 250px">
      <col style="width: 120px">
      <col style="width: 80px">
      <col style="width: 135px">
      <col style="width: 150px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
      <col style="width: 120px">
    </colgroup>
    <b-thead head-variant="light">
      <b-tr>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >ID</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >제약사</b-th>
        <b-th
          rowspan="2"
        >제품명</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >지역</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >회차</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >게시 기간</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >진료과</b-th>
        <b-th
          colspan="2"
          style="text-align: center; padding-top: 0.8rem; padding-bottom: 0.8rem;"
        >구간 조건</b-th>
        <b-th
          colspan="3"
          style="text-align: center;"
        >1회 구간 노출수</b-th>
        <b-th
          colspan="3"
          style="text-align: center;"
        >2회 구간 노출수</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >메세지<br>클릭수</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >클릭수<br>(중복제거)</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >닥터인포<br>클릭수</b-th>
        <b-th
          rowspan="2"
          style="text-align: center;"
        >닥터인포<br>클릭수<br>(중복제거)</b-th>
      </b-tr>
      <b-tr>
        <b-th
          style="text-align: center; top: 40px;"
        >1회 구간</b-th><b-th
          style="text-align: center; top: 40px;"
        >2회 구간</b-th>

        <b-th
          style="text-align: center; top: 40px;"
        >{{ searchType === 2 ? '처방의원수' : '처방의사수' }}</b-th>
        <b-th
          style="text-align: center; top: 40px;"
        >{{ searchType === 2 ? '신규처방처' : '신규처방수' }}</b-th>
        <b-th
          style="text-align: center; top: 40px;"
        >{{ searchType === 2 ? '재처방처' : '재처방수' }}</b-th>

        <b-th
          style="text-align: center; top: 40px;"
        >{{ searchType === 2 ? '처방의원수' : '처방의사수' }}</b-th>
        <b-th
          style="text-align: center; top: 40px;"
        >{{ searchType === 2 ? '신규처방처' : '신규처방수' }}</b-th>
        <b-th
          style="text-align: center; top: 40px;"
        >{{ searchType === 2 ? '재처방처' : '재처방수' }}</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="cumulative in data">
        <template v-for="(period, pIdx) in cumulative.liveDiPeriods">
          <template v-for="(dept, index) in period.liveDiDepts">
            <b-tr
              :key="`cumulative-${cumulative.regionName}-${cumulative.liveInfoId}-${period.seq}-${pIdx}-${index}`"
            >
              <b-td
                v-if="pIdx === 0 && index === 0"
                :rowspan="cumulative.rowSpanCount"
                style="text-align: center;"
              >
                {{ /*ID*/ cumulative.liveInfoId }}
              </b-td>
              <b-td
                v-if="pIdx === 0 && index === 0"
                :rowspan="cumulative.rowSpanCount"
                style="text-align: center;"
              >
                {{ /*제약사*/ cumulative.clientName }}
              </b-td>
              <b-td
                v-if="pIdx === 0 && index === 0"
                :rowspan="cumulative.rowSpanCount"
              >
                {{ /*제품명*/ cumulative.brandName }}
              </b-td>
              <b-td
                v-if="pIdx === 0 && index === 0"
                :rowspan="cumulative.rowSpanCount"
                style="text-align: center;"
              >
                {{ /*지역*/ cumulative.regionName }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="period.liveDiDepts.length"
                style="text-align: center;"
              >
                {{ /*회차*/ period.seq }}
              </b-td>
              <b-td
                v-if="index === 0"
                :rowspan="period.liveDiDepts.length"
                style="text-align: center;"
              >
                {{ /*게시기간*/ period.periodStr.replaceAll('~', ' ~ ') }}
              </b-td>
              <b-td
                style="text-align: center;"
              >{{ /*진료과*/ dept.medicalDeptName }}</b-td>
              <b-td
                v-if="index === 0"
                :rowspan="period.liveDiDepts.length"
                style="text-align: center;"
              >{{ /*구간조건(1회)*/ period.firstCount | $numberFormatter }}</b-td>
              <b-td
                v-if="index === 0"
                :rowspan="period.liveDiDepts.length"
                style="text-align: center;"
              >{{ /*구간조건(2회)*/ period.secondCount | $numberFormatter }}</b-td>
              <b-td
                style="text-align: center;"
              >{{ /*1회 노출수 처방처*/ dept.firstTermGroupCount | $numberFormatter }} </b-td>
              <b-td
                style="text-align: center;"
              >{{ /*1회 노출수 신규처방*/ dept.firstTermNewCount | $numberFormatter }} </b-td>
              <b-td
                style="text-align: center;"
              >{{ /*1회 노출수 재처방*/ dept.firstTermReCount | $numberFormatter }} </b-td>
              <b-td
                style="text-align: center;"
              >{{ /*2회 노출수 처방처*/ dept.secondTermGroupCount | $numberFormatter }} </b-td>
              <b-td
                style="text-align: center;"
              >{{ /*2회 노출수 신규처방*/ dept.secondTermNewCount | $numberFormatter }} </b-td>
              <b-td
                style="text-align: center;"
              >{{ /*2회 노출수 재처방*/ dept.secondTermReCount | $numberFormatter }} </b-td>
              <b-td
                style="text-align: center;"
              >
                {{ /*메시지 클릭수*/ dept.totalClickCount | $numberFormatter }}
              </b-td>
              <b-td
                style="text-align: center;"
              >
                {{ /*클릭수_중복제거*/ dept.groupClickCount | $numberFormatter }}
              </b-td>
              <b-td
                style="text-align: center;"
              >
                {{ /*닥터인포 클릭수*/ dept.totalDrInfoClickCount | $numberFormatter }}
              </b-td>
              <b-td
                style="text-align: center;"
              >
                {{ /*닥터인포 클릭수_중복제거*/ dept.groupDrInfoClickCount | $numberFormatter }}
              </b-td>
            </b-tr>
          </template>
        </template>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTr, BTh, BTbody, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    searchType: {
      type: Number,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}
</style>
