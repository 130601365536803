<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              조회 타입
            </p>
            <b-form-radio-group
              v-model="query.isPrefixSum"
            >
              <b-form-radio :value="true">
                누적
              </b-form-radio>
              <b-form-radio :value="false">
                일별
              </b-form-radio>
            </b-form-radio-group>
            <p class="h5 mt-50 mr-75 ml-50">
              조회 기준
            </p>
            <b-form-radio-group
              v-model="query.countingGroup"
            >
              <b-form-radio :value="2">
                의원
              </b-form-radio>
              <b-form-radio :value="1">
                의사
              </b-form-radio>
            </b-form-radio-group>
            <b-form-datepicker
              v-model="query.searchStartDate"
              class="el-def"
              placeholder="게시시작일(Fr)"
              style="width: 250px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.searchEndDate"
              class="el-def"
              placeholder="게시시작일(To)"
              style="width: 250px"
            />
            <label style="width: 160px">(*당일데이터 조회 불가)</label>
          </b-col>
          <b-col
            md="3"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="11"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-1"
          >

            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width:20%"
            />

            <v-select
              v-model="query.postStatusType"
              :options="codes.postStatusType"
              class="invoice-filter-select el-def"
              :reduce="(option) => option.code"
              placeholder="게시상태"
            />

            <b-form-input
              v-model="query.brandName"
              class="el-def"
              placeholder="제품명"
              style="width: 60%;"
            />

            <b-form-input
              v-model="query.liveInfoId"
              class="el-def"
              placeholder="ID"
              style="width: 140px;"
            />

          </b-col>
          <b-col
            cols="1"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-1"
          >

            <b-button
              variant="outline-secondary"
              class="btn-search"
              @click.prevent="resetData()"
            >
              초기화
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="10">
            <b-form-group>
              <b-form-input
                id="liveinfo-cond-region"
                v-model="query.regionName"
                readonly
                placeholder="지역"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              @click.prevent="showRegionSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group>
              <b-form-input
                id="liveinfo-cond-dept"
                v-model="query.medicalDeptNames"
                readonly
                placeholder="진료과"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              @click.prevent="showMediDeptSelect = true"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>목록</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <cumulative-grid
        v-if="isPrefixSum"
        :data="liveDiData"
        :search-type="countingGroup"
      />

      <daily-grid
        v-else-if="!isPrefixSum"
        :data="liveDiData"
        :search-type="countingGroup"
      />
    </b-card>

    <region-select
      :show-region-select="showRegionSelect"
      :selected-value="query.regions"
      @close="regionSelect.close"
    />

    <medi-dept-select
      :show-medi-dept-select="showMediDeptSelect"
      :selected-value="query.medicalDepts"
      @close="mediDeptSelect.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import vSelect from "vue-select"
import {
  BFormInput, BFormGroup, BRow, BCol, BButton,
} from 'bootstrap-vue'
import RegionSelect from '@/views/apps/popup/RegionSelect.vue'
import MediDeptSelect from '@/views/apps/popup/MediDeptSelect.vue'
import { arrayJoinStr } from '@core/utils/filter'
import CumulativeGrid from './grid/CumulativeGrid.vue'
import DailyGrid from './grid/DailyGrid.vue'

export default {
  components: {
    vSelect,
    CumulativeGrid,
    DailyGrid,
    RegionSelect,
    MediDeptSelect,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })
    const codes = ref({
      regions: [],
      postStatusType: [],
      clients: [],
    })

    const fetchCodes = () => {
      axios.get("/fa/druglanding/codes")
        .then(rs => {
          const { hcpRegion, postStatusType, clients } = rs.data

          codes.value.regions = [{ code: 0, label: '전체' }, ...hcpRegion]
          codes.value.postStatusType = postStatusType
          codes.value.clients = clients
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const showRegionSelect = ref(false)
    const regionSelect = {
      close: rs => {
        showRegionSelect.value = false

        if (rs) {
          query.value.regions = arrayJoinStr(rs, 'code')
          query.value.regionName = arrayJoinStr(rs, 'label')
        }
      },
    }

    const showMediDeptSelect = ref(false)
    const mediDeptSelect = {
      close: rs => {
        showMediDeptSelect.value = false

        if (rs) {
          query.value.medicalDepts = arrayJoinStr(rs, 'code')
          query.value.medicalDeptNames = arrayJoinStr(rs, 'label')
        }
      },
    }

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const query = ref({
      searchStartDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
      searchEndDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
      countingGroup: 1,
      clientId: null,
      regions: null,
      regionName: null,
      postStatusType: null,
      medicalDepts: null,
      medicalDeptNames: '',
      brandName: null,
      isPrefixSum: true,
      liveInfoId: null,
    })

    const countingGroup = ref(1)
    const isPrefixSum = ref(true)
    const liveDiData = ref([])

    const resetData = () => {
      // 상세 검색조건 초기화
      query.value.clientId = null
      query.value.regions = null
      query.value.regionName = null
      query.value.postStatusType = null
      query.value.brandName = null
      query.value.medicalDepts = null
      query.value.medicalDeptNames = ''
      query.value.medicalDeptNames = ''
      query.value.liveInfoId = null
    }

    const fetchData = () => {
      const frDate = dayjs(query.value.searchStartDate)
      const toDate = dayjs(query.value.searchEndDate)

      if (!query.value.liveInfoId?.trim()) {
        pushToast('warning', 'ID는 필수 검색조건입니다.')
        return
      }
      // // 조회 기간 제한 (해제)
      // if (toDate.diff(frDate, 'day') > 90) {
      //   pushToast('warning', '조회 기간은 최대 90일을 넘을 수 없습니다.')
      //   return
      // }

      // 종료일이 시작일보다 빠를 수 없음
      if (toDate.diff(frDate, 'day') < 0) {
        pushToast('warning', '종료일이 시작일보다 앞설 수 없습니다.')
        return
      }

      const type = query.value.isPrefixSum ? 'summary' : 'detail'

      axios.post(`/fa/statistics-ext/live-di/${type}`, {
        startDate: query.value.searchStartDate,
        endDate: query.value.searchEndDate,
        countingGroup: query.value.countingGroup,
        clientId: query.value.clientId,
        // region: query.value.region,
        postStatusType: query.value.postStatusType,
        medicalDeptsStr: query.value.medicalDepts,
        regionsStr: query.value.regions,
        brandName: query.value.brandName,
        // isPrefixSum: query.value.isPrefixSum,
        liveInfoId: query.value.liveInfoId,
      })
        .then(rs => {
          isPrefixSum.value = query.value.isPrefixSum
          countingGroup.value = query.value.countingGroup

          if (rs?.data) {
            liveDiData.value = rs.data
          }
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: {
              title: `데이터 조회 중 오류가 발생하였습니다.${err}`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = async () => {
      if (isPrefixSum.value) cumulativeDownload()
      else dailyDownload()
    }

    const cumulativeDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = 'ID'
      worksheet.getCell('B1').value = '제약사'
      worksheet.getCell('C1').value = '제품명'
      worksheet.getCell('D1').value = '지역'
      worksheet.getCell('E1').value = '회차'
      worksheet.getCell('F1').value = '게시 기간'
      worksheet.getCell('G1').value = '진료과'
      worksheet.getCell('H1').value = '구간조건'
      // worksheet.getCell('I1').value = '구간조건'
      worksheet.getCell('J1').value = '1회 구간 노출수'
      // worksheet.getCell('K1').value = '1회 구간 노출수'
      // worksheet.getCell('L1').value = '1회 구간 노출수'
      worksheet.getCell('M1').value = '2회 구간 노출수'
      // worksheet.getCell('N1').value = '2회 구간 노출수'
      // worksheet.getCell('O1').value = '2회 구간 노출수'
      worksheet.getCell('P1').value = '메세지 클릭수'
      worksheet.getCell('Q1').value = '클릭수 (중복제거)'
      worksheet.getCell('R1').value = '닥터인포 클릭수'
      worksheet.getCell('S1').value = '닥터인포 클릭수 (중복제거)'

      worksheet.mergeCells(`A1:A2`)
      worksheet.mergeCells(`B1:B2`)
      worksheet.mergeCells(`C1:C2`)
      worksheet.mergeCells(`D1:D2`)
      worksheet.mergeCells(`E1:E2`)
      worksheet.mergeCells(`F1:F2`)
      worksheet.mergeCells(`G1:G2`)

      worksheet.mergeCells(`H1:I1`)
      worksheet.getCell('H2').value = '1회 구간'
      worksheet.getCell('I2').value = '2회 구간'
      worksheet.mergeCells(`J1:L1`)
      worksheet.getCell('J2').value = countingGroup.value === 2 ? '처방의원수' : '처방의사수'
      worksheet.getCell('K2').value = countingGroup.value === 2 ? '신규처방처' : '신규처방수'
      worksheet.getCell('L2').value = countingGroup.value === 2 ? '재처방처' : '재처방수'
      worksheet.mergeCells(`M1:O1`)
      worksheet.getCell('M2').value = countingGroup.value === 2 ? '처방의원수' : '처방의사수'
      worksheet.getCell('N2').value = countingGroup.value === 2 ? '신규처방처' : '신규처방수'
      worksheet.getCell('O2').value = countingGroup.value === 2 ? '재처방처' : '재처방수'

      worksheet.mergeCells(`P1:P2`)
      worksheet.mergeCells(`Q1:Q2`)
      worksheet.mergeCells(`R1:R2`)
      worksheet.mergeCells(`S1:S2`)

      worksheet.columns = [
        { key: "liveInfoId", width: 15 },
        { key: "clientName", width: 25 },
        { key: "brandName", width: 25 },
        { key: "regionName", width: 15 },
        { key: "seq", width: 10 },
        { key: "periodStr", width: 15 },
        { key: "medicalDeptName", width: 15 },
        { key: "firstCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      let startRow = 3
      let idStartRow = startRow
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      liveDiData.value.map(liveDi => {
        idStartRow = startRow

        if (liveDi.liveDiPeriods.length > 0) {
        // eslint-disable-next-line array-callback-return
          liveDi.liveDiPeriods.map(period => {
            const periodStartRow = startRow
            // let periodEndRow = periodStartRow + period.rowSpanCount - 1 + (1 * period.liveDiDaily.length)
            let periodEndRow = periodStartRow + period.liveDiDepts.length - 1

            period.liveDiDepts.map(dept => worksheet.addRow({
              liveInfoId: liveDi.liveInfoId,
              clientName: liveDi.clientName,
              brandName: liveDi.brandName,
              regionName: liveDi.regionName,
              seq: period.seq,
              periodStr: period.periodStr,
              medicalDeptName: dept.medicalDeptName,
              firstCount: period.firstCount,
              secondCount: period.secondCount,
              firstTermGroupCount: dept.firstTermGroupCount,
              firstTermNewCount: dept.firstTermNewCount,
              firstTermReCount: dept.firstTermReCount,
              secondTermGroupCount: dept.secondTermGroupCount,
              secondTermNewCount: dept.secondTermNewCount,
              secondTermReCount: dept.secondTermReCount,
              totalClickCount: dept.totalClickCount,
              groupClickCount: dept.groupClickCount,
              totalDrInfoClickCount: dept.totalDrInfoClickCount,
              groupDrInfoClickCount: dept.groupDrInfoClickCount,
            }))

            worksheet.mergeCells(`H${periodStartRow}:H${periodEndRow}`)
            worksheet.mergeCells(`I${periodStartRow}:I${periodEndRow}`)

            endRow += 1

            if (liveDi.liveDiPeriods.length > 0) {
              worksheet.addRow({
                liveInfoId: liveDi.liveInfoId,
                clientName: liveDi.clientName,
                brandName: liveDi.brandName,
                seq: period.seq,
                periodStr: period.periodStr,
                medicalDeptName: '회차별 소계',
                firstCount: period.firstCount,
                secondCount: period.secondCount,
                firstTermGroupCount: period.subTotal.firstTermGroupCount,
                firstTermNewCount: period.subTotal.firstTermNewCount,
                firstTermReCount: period.subTotal.firstTermReCount,
                secondTermGroupCount: period.subTotal.secondTermGroupCount,
                secondTermNewCount: period.subTotal.secondTermNewCount,
                secondTermReCount: period.subTotal.secondTermReCount,
                totalClickCount: period.subTotal.totalClickCount,
                groupClickCount: period.subTotal.groupClickCount,
                totalDrInfoClickCount: period.subTotal.totalDrInfoClickCount,
                groupDrInfoClickCount: period.subTotal.groupDrInfoClickCount,
              })

              periodEndRow += 1

              worksheet.mergeCells(`G${periodEndRow}:I${periodEndRow}`)

              worksheet.mergeCells(`E${periodStartRow}:E${periodEndRow}`)
              worksheet.mergeCells(`F${periodStartRow}:F${periodEndRow}`)
            }

            startRow = endRow + period.liveDiDepts.length
            endRow = startRow
          })
          worksheet.mergeCells(`A${idStartRow}:A${endRow - 1}`)
          worksheet.mergeCells(`B${idStartRow}:B${endRow - 1}`)
          worksheet.mergeCells(`C${idStartRow}:C${endRow - 1}`)
          worksheet.mergeCells(`D${idStartRow}:D${endRow - 1}`)
        }
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '회차별 소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "라이브DI 통계(누적).xlsx")
    }

    const dailyDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = 'ID'
      worksheet.getCell('B1').value = '제약사'
      worksheet.getCell('C1').value = '제품명'
      worksheet.getCell('D1').value = '지역'
      worksheet.getCell('E1').value = '회차'
      worksheet.getCell('F1').value = '기간'
      worksheet.getCell('G1').value = '일자'
      worksheet.getCell('H1').value = '진료과'
      worksheet.getCell('I1').value = '구간조건'
      // worksheet.getCell('J1').value = '구간조건'
      worksheet.getCell('K1').value = '1회 구간 노출수'
      // worksheet.getCell('L1').value = '1회 구간 노출수'
      // worksheet.getCell('M1').value = '1회 구간 노출수'
      worksheet.getCell('N1').value = '2회 구간 노출수'
      // worksheet.getCell('O1').value = '2회 구간 노출수'
      // worksheet.getCell('P1').value = '2회 구간 노출수'
      worksheet.getCell('Q1').value = '메세지 클릭수'
      worksheet.getCell('R1').value = '클릭수 (중복제거)'
      worksheet.getCell('S1').value = '닥터인포 클릭수'
      worksheet.getCell('T1').value = '닥터인포 클릭수 (중복제거)'

      worksheet.mergeCells(`A1:A2`)
      worksheet.mergeCells(`B1:B2`)
      worksheet.mergeCells(`C1:C2`)
      worksheet.mergeCells(`D1:D2`)
      worksheet.mergeCells(`E1:E2`)
      worksheet.mergeCells(`F1:F2`)
      worksheet.mergeCells(`G1:G2`)
      worksheet.mergeCells(`H1:H2`)

      worksheet.mergeCells(`I1:J1`)
      worksheet.getCell('I2').value = '1회 구간'
      worksheet.getCell('J2').value = '2회 구간'
      worksheet.mergeCells(`K1:M1`)
      worksheet.getCell('K2').value = countingGroup.value === 2 ? '처방의원수' : '처방의사수'
      worksheet.getCell('L2').value = countingGroup.value === 2 ? '신규처방처' : '신규처방수'
      worksheet.getCell('M2').value = countingGroup.value === 2 ? '재처방처' : '재처방수'
      worksheet.mergeCells(`N1:P1`)
      worksheet.getCell('N2').value = countingGroup.value === 2 ? '처방의원수' : '처방의사수'
      worksheet.getCell('O2').value = countingGroup.value === 2 ? '신규처방처' : '신규처방수'
      worksheet.getCell('P2').value = countingGroup.value === 2 ? '재처방처' : '재처방수'

      worksheet.mergeCells(`Q1:Q2`)
      worksheet.mergeCells(`R1:R2`)
      worksheet.mergeCells(`S1:S2`)
      worksheet.mergeCells(`T1:T2`)

      worksheet.columns = [
        { key: "liveInfoId", width: 15 },
        { key: "clientName", width: 25 },
        { key: "brandName", width: 25 },
        { key: "regionName", width: 15 },
        { key: "seq", width: 10 },
        { key: "periodStr", width: 15 },
        { key: "dateStr", width: 15 },
        { key: "medicalDeptName", width: 15 },
        { key: "firstCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "firstTermReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermNewCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "secondTermReCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "totalDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "groupDrInfoClickCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      let startRow = 3
      let endRow = startRow
      // eslint-disable-next-line array-callback-return
      liveDiData.value.map(liveDi => {
        const liveDiStartRow = startRow
        const liveDiEndRow = liveDiStartRow + liveDi.rowSpanCount - 1 + (1 * liveDi.liveDiPeriods.length) + (1 * liveDi.dayCount)

        // eslint-disable-next-line array-callback-return
        liveDi.liveDiPeriods.map(period => {
          const periodStartRow = startRow
          let periodEndRow = periodStartRow + period.rowSpanCount - 1 + (1 * period.liveDiDaily.length)

          // eslint-disable-next-line array-callback-return
          period.liveDiDaily.map(day => {
            const dayStartRow = startRow
            let dayEndRow = startRow + day.liveDiDepts.length - 1

            day.liveDiDepts.map(dept => {
              worksheet.addRow({
                liveInfoId: liveDi.liveInfoId,
                clientName: liveDi.clientName,
                brandName: liveDi.brandName,
                regionName: liveDi.regionName,
                seq: period.seq,
                periodStr: period.periodStr,
                dateStr: day.dateStr,
                medicalDeptName: dept.medicalDeptName,
                firstCount: period.firstCount,
                secondCount: period.secondCount,
                firstTermGroupCount: dept.firstTermGroupCount,
                firstTermNewCount: dept.firstTermNewCount,
                firstTermReCount: dept.firstTermReCount,
                secondTermGroupCount: dept.secondTermGroupCount,
                secondTermNewCount: dept.secondTermNewCount,
                secondTermReCount: dept.secondTermReCount,
                totalClickCount: dept.totalClickCount,
                groupClickCount: dept.groupClickCount,
                totalDrInfoClickCount: dept.totalDrInfoClickCount,
                groupDrInfoClickCount: dept.groupDrInfoClickCount,
              })
              endRow += 1
            })

            // 1회, 2회 구간 병합
            worksheet.mergeCells(`I${dayStartRow}:I${dayEndRow}`)
            worksheet.mergeCells(`J${dayStartRow}:J${dayEndRow}`)

            if (period.liveDiDaily.length > 0) {
              worksheet.addRow({
                liveInfoId: liveDi.liveInfoId,
                clientName: liveDi.clientName,
                brandName: liveDi.brandName,
                regionName: liveDi.regionName,
                seq: period.seq,
                periodStr: period.periodStr,
                dateStr: day.dateStr,
                medicalDeptName: '일자별 소계',
                firstCount: '',
                secondCount: '',
                firstTermGroupCount: day.dailyTotal.firstTermGroupCount,
                firstTermNewCount: day.dailyTotal.firstTermNewCount,
                firstTermReCount: day.dailyTotal.firstTermReCount,
                secondTermGroupCount: day.dailyTotal.secondTermGroupCount,
                secondTermNewCount: day.dailyTotal.secondTermNewCount,
                secondTermReCount: day.dailyTotal.secondTermReCount,
                totalClickCount: day.dailyTotal.totalClickCount,
                groupClickCount: day.dailyTotal.groupClickCount,
                totalDrInfoClickCount: day.dailyTotal.totalDrInfoClickCount,
                groupDrInfoClickCount: day.dailyTotal.groupDrInfoClickCount,
              })

              worksheet.mergeCells(`H${endRow}:J${endRow}`)

              dayEndRow += 1
            }

            // 일자 병합
            worksheet.mergeCells(`G${dayStartRow}:G${dayEndRow}`)

            startRow = dayEndRow + 1

            endRow = startRow
          })

          if (liveDi.liveDiPeriods.length > 0) {
            worksheet.addRow({
              liveInfoId: liveDi.liveInfoId,
              clientName: liveDi.clientName,
              brandName: liveDi.brandName,
              regionName: liveDi.regionName,
              seq: period.seq,
              periodStr: period.periodStr,
              dateStr: '회차별 소계',
              medicalDeptName: '',
              firstCount: '',
              secondCount: '',
              firstTermGroupCount: period.subTotal.firstTermGroupCount,
              firstTermNewCount: period.subTotal.firstTermNewCount,
              firstTermReCount: period.subTotal.firstTermReCount,
              secondTermGroupCount: period.subTotal.secondTermGroupCount,
              secondTermNewCount: period.subTotal.secondTermNewCount,
              secondTermReCount: period.subTotal.secondTermReCount,
              totalClickCount: period.subTotal.totalClickCount,
              groupClickCount: period.subTotal.groupClickCount,
              totalDrInfoClickCount: period.subTotal.totalDrInfoClickCount,
              groupDrInfoClickCount: period.subTotal.groupDrInfoClickCount,
            })

            worksheet.mergeCells(`G${endRow}:J${endRow}`)

            periodEndRow += 1
          }

          // 시퀀스(회차), 기간 병합
          worksheet.mergeCells(`E${periodStartRow}:E${periodEndRow}`)
          worksheet.mergeCells(`F${periodStartRow}:F${periodEndRow}`)

          startRow = periodEndRow + 1
          endRow = startRow
        })

        worksheet.mergeCells(`A${liveDiStartRow}:A${liveDiEndRow}`)
        worksheet.mergeCells(`B${liveDiStartRow}:B${liveDiEndRow}`)
        worksheet.mergeCells(`C${liveDiStartRow}:C${liveDiEndRow}`)
        worksheet.mergeCells(`D${liveDiStartRow}:D${liveDiEndRow}`)
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '일자별 소계' || cell.value === '회차별 소계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "라이브DI 통계(일별).xlsx")
    }

    return {
      codes,
      fetchCodes,
      query,
      countingGroup,
      isPrefixSum,
      liveDiData,
      resetData,
      fetchData,
      excelDownload,
      cumulativeDownload,
      dailyDownload,
      regionSelect,
      mediDeptSelect,
      showRegionSelect,
      showMediDeptSelect,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.dropdown-menu {
  z-index: 100 !important;
}
</style>
